body {
  margin: 0;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Polaris-Navigation {
  position: sticky;
  top: 0;
  height: 100vh !important;
}

.InputContainer {
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1rem;
}

.Polaris-Page {
  width: 80vw !important;
  max-width: none !important;
}

.Polaris-DataTable__Cell img {
  width: 3vw;
  padding: 1rem 0;
}

.Polaris-ResourceList-Item__Content h3 {
  display: flex;
  align-items: center;
}

.Polaris-Modal-Dialog {
  outline: none;
}

.Polaris-Thumbnail__Image {
  height: 100%;
  margin: 0;
  object-fit: cover;
  width: 100%;
}
